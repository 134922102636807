@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Navbar";

#{$selector}

 {
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  z-index: 1199;
  width: 100%;
  padding: 0 $space-lg;
  background-color: $light--backgroundWhite;
  box-shadow: $elevation--card;

  &--dark {
    background-color: $dark--backgroundWhite;
    box-shadow: $elevation--card--dark;
  }

  &__logo {
    position: relative;
    z-index: 1;
    width: 203px;
    height: 30px;
    align-self: center;
  }

  &__bar {
    max-width: $screen-max-contents-width;
    margin: 0 auto !important;
  }

  &__nav {
    position: absolute;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100%;
    margin: 0;
    transform: translateX(var(--nav-width));
    transition: transform 0.5s;

    &__icon {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      padding-left: $space-sm;
      background-color: $light--backgroundWhite;

      &--dark {
        background-color: $dark--backgroundWhite;
      }
    }

    &__container {
      margin: $space-xl;
      overflow: hidden;
      border-radius: 50px;
    }

    &__content {
      display: flex;
      padding: 0 $space-lg 0 0;
    }

    &__list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0 $space-xl 0 $space-md;
      list-style-type: none;
    }

    &--is-visible {
      position: relative;
      transform: translateX(0);
      transition: transform 0.5s;
    }
  }

  &__toolbar__action__button {
    margin-right: $space-lg !important;
  }

  &__mobile__drawer {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $space-lg;

      &__actions {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__switch {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }

    &__animation {
      max-width: 400px;

      @include screenLargerThan($screen-md) {
        &__container {
          position: absolute !important;
          bottom: 0;
          right: -70px;
          width: 400px;
          overflow: hidden;
        }
      }
    }

    &__options {
      margin: $space-lg 0;

      &__button {
        width: 100% !important;
      }
    }
  }

  &--primary {
    color: $light--primary;

    @include when-hovered {
      color: $light--base;
    }
  }

  &--secondary {
    color: $light--secondary;
  }

  &--white {
    color: $white;
  }
}
